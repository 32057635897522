.root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.left {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  margin: 8px;
  margin-right: 16px;
}

.mid {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  margin: 8px;
  margin-left: 16px;
}

.right {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  margin: 8px;
  margin-left: 16px;
}

.row {
  padding-left: 16px;
  margin-bottom: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-weight: bold !important;
  color: #d34500;
  margin-bottom: 8px;
}

.label {
  font-weight: bold !important;
  color: grey !important;
}

.field {
  width: 130px;
}
