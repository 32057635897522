.root {
  width: 350px;
  height: 620px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.body {
  width: 280px;
  height: 500px;
  overflow-y: auto;
  position: absolute;
  top: 110px;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.row {
  margin: 4px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  font-weight: bold;
  font-size: 12px;
}

.input {
  font-size: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 4px 16px;
}

.value {
  font-size: 12px;
  padding: 4px 16px;
}
