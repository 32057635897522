.root {
  display: flex;
  margin-left: 4px;
}

.weekday {
  color: grey;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.35;
  margin-right: 2px;
}

.date {
  color: grey;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.35;
}

@media (min-height: 801px) {
  .weekday {
    font-size: 12px;
  }

  .date {
    font-size: 12px;
  }
}
