.root {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 8px;
}

.col {
  flex: 1;
}

.label {
  text-align: center;
  font-weight: bold;
  color: grey;
}

.stats {
  text-align: center;
  font-weight: bold;
  color: green;
}
