.root {
  display: flex;
}

.weekday {
  color: black;
  font-size: 10px;
  line-height: 1.35;
  margin-right: 2px;
}

.date {
  color: black;
  font-size: 10px;
  line-height: 1.35;
}

@media (min-height: 801px) {
  .weekday {
    font-size: 12px;
  }

  .date {
    font-size: 12px;
  }
}
