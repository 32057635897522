.wrapper {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.wrapper:hover {
  background-color: rgba(126, 228, 254, 0.18);
}

.root {
  margin: 8px 12px;
  height: calc(100% - 24px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.empty {
  height: calc(100% - 16px);
  margin: 8px;
  border: 1px dashed red;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rest {
  height: calc(100% - 16px);
  margin: 8px;
  border: 1px solid grey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.entity {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.icon {
  width: 16px !important;
  height: 16px !important;
  color: grey;
  margin-right: 8px;
}

.label {
  font-size: 14px !important;
  font-weight: bold;
  color: grey;
}
