.root {
  position: relative;
}

.icon {
  opacity: 0;
}

.root:hover .icon {
  opacity: 1;
}
