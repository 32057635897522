.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 88px;
  height: 30px;
  margin: 1px;
  border-radius: 6px;
}

.root-draft {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 84px;
  height: 26px;
  border-radius: 6px;
  border: 1.5px dashed white;
}

.left-container {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  max-width: 60%;
  overflow: hidden;
}

.right-container {
  display: flex;
  flex-direction: column;
  padding-right: 5px;
}

.position-container {
  display: flex;
}

.text {
  color: white;
  line-height: 1.35;
  font-size: 10px;
  font-weight: 600;
  white-space: nowrap;
}

.icon {
  color: white;
  width: 12px;
  height: 12px;
  margin-left: 2px;
}

@media (min-height: 801px) {
  .root {
    width: 108px;
    height: 38px;
  }

  .root-draft {
    width: 104px;
    height: 34px;
    border: 2px dashed white;
  }

  .left-container {
    padding-left: 6px;
  }

  .right-container {
    padding-right: 6px;
  }

  .text {
    font-size: 12px;
  }

  .icon {
    width: 14px;
    height: 14px;
  }
}
