.root {
  display: flex;
  /* flex-grow: 1; */
  flex-direction: column;
  overflow: auto;
}

.wrapper {
  /* width: calc(178px * 7); */
  display: flex;
  flex-wrap: wrap;
  margin-left: 16px;
}

.cell {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 176px;
  height: 120px;
  align-items: flex-start;
  justify-content: flex-start;
  border: 1px solid #ddd;
}

.star {
  position: absolute;
  top: 2px;
  right: 2px;
}

@media (min-height: 801px) {
  .wrapper {
    width: calc(222px * 7);
  }

  .cell {
    min-width: 220px;
    height: 140px;
  }
}
